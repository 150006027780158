import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import "select2";

//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  disableBodyScroll(scrollableNav);
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  enableBodyScroll(scrollableNav);
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region offcavas menu mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion
//#region close menu search
$(".close-search").on("click", function (e) {
  $(".collapse-search").removeClass("show");
});
//#endregion

//#region form select2
$(document).ready(function () {
  function formatState(state) {
    if (!state.id) {
      return state.text;
    }
    var baseUrl = "/images";
    var $state = $(
      '<span><img src="' + baseUrl + '/' + state.element.value + '.svg" class="img-flag" /> ' + state.text + '</span>'
    );
    return $state;
  };
  $('.select-protezione').select2({
    placeholder: 'Segli la protezione',
    templateResult: formatState,
    minimumResultsForSearch: Infinity
  });
  $('.select-marca').select2({
    placeholder: 'Segli la marca',
    minimumResultsForSearch: Infinity
  });
  $('.select-memoria').select2({
    placeholder: 'Segli la memoria',
    minimumResultsForSearch: Infinity
  });

});
//#endregion
